$(document).ready(function () {
  // Add class "touched" to inputs, to apply error classes if necessary
  $('[type="submit"]').on('click', function (e) {
    $('.contact-form input , .contact-form textarea').addClass('touched')
  })

  // show thank you screen
  $('.contact-form__form').submit(async function (e) {
    const senderIp = ''

    $('[type="submit"]').text('Sending')
    $('[type="submit"]').append('<span class="loader"></span>')

    const formElements = await $(this).serializeArray()
    formElements.push({ name: 'senderIp', value: senderIp })

    if (typeof mailReceivers !== 'undefined') {
      formElements.push({ name: 'Receivers', value: mailReceivers })
    }

    const serviceUrl = $('[type="submit"]').data().service
    const form = $(this)

    $.ajax({
      method: 'POST',
      url: serviceUrl,
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(formElements),
      success: function (data) {
        if (data.success) {
          form.hide()
          $('.contact-form__header').hide()
          $('.contact-form__tankyou').fadeIn('fast')
        } else {
          $('[type="submit"]').text('Send')
          $('[type="submit"]').remove('.loader')
        }
      }
    })
  })

  // show contact form again
  $('.contact-form__tankyou button').on('click', function () {
    $('.contact-form__form').fadeIn('fast')
    $('.contact-form__header').fadeIn('fast')
    $('.contact-form__tankyou').hide()

    $('[type="submit"]').text('Send')
    $('[type="submit"]').remove('.loader')

    // $('[name=contactForm]').find('[type="submit"]').prop('disabled', true)

    $('.contact-form__form').each(function () {
      $(this).find(':input').each(function () {
        $(this).removeClass('touched')
      })
      this.reset()
    })
  })
})
